import { registerApplication, start } from 'single-spa'
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine
} from 'single-spa-layout'
import microfrontendLayout from './microfrontend-layout.html'
import { HTMLLayoutData } from 'single-spa-layout/dist/types/isomorphic/constructRoutes'

//polyfills for angular app
window['global'] = window
global.Buffer = require('buffer').Buffer
global.process = require('process')

const data = {
  loaders: {
    pageLoader: `
		<div id="loading">
        <div style="bottom:0;left:0;overflow:hidden;position:absolute;right:0;top:0">
            <div style="animation:a-h .5s 1.25s 1 linear forwards,a-nt .6s 1.25s 1 cubic-bezier(0,0,.2,1);background:#eee;border-radius:50%;height:800px;left:50%;margin:-448px -400px 0;position:absolute;top:50%;transform:scale(0);width:800px"></div>
        </div>
        <div style="height:100%;text-align:center">
          <div style="height:50%;margin:0 0 -140px"></div>
            <div id="nlpt"></div>
            <div style="animation:a-s .25s 1.25s 1 forwards;opacity:0" class="msg">Đang tải xuống tài nguyên&hellip;</div>
        </div>
    </div>`
  },
  props: {}
}

const routes = constructRoutes(microfrontendLayout, data)
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name)
  }
})
const layoutEngine = constructLayoutEngine({ routes, applications })

applications.forEach(registerApplication)
layoutEngine.activate()
start()
